import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner5@2x.jpg'),
    heading: "Member-get-member-get-member-get-member-get-member",
    subheading: "Accelerate your growth by encouraging your customers to refer prospects to your business and then reward them when they sign up and stay. Custom business rules help you to keep your scheme in check, and you can even issue gift cards and prepaid debit cards automatically for successful referrals."
  }

  return(
    <Layout>
      <SEO 
        title="Referral Programs | Billsby Pro | Take your subscription business to the next level" 
        description="Referral Programs and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://ww.billsby.com/pro/referral-program"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;